import { html, type TemplateResult } from 'lit-html'
import { Subject } from 'rxjs'

import { BaseTemplate } from 'app/core/base-template'
import { AppLabels } from 'app/models/app-translations.model'
import { ConsentAltBoxEvents, ConsentAltConfiguration, ConsentBoxAltType } from './consent-alt-box.model'

interface TemplateData {
  consentType: ConsentBoxAltType
  configuration?: ConsentAltConfiguration
  loadingState?: boolean
}

export class ConsentAltBoxTemplate extends BaseTemplate<TemplateData, ConsentAltBoxEvents> {
  private readonly learnMoreClick$: Subject<unknown>
  private readonly runClick$: Subject<unknown>
  private currentType!: ConsentBoxAltType

  constructor() {
    super()
    this.registerEvent(ConsentAltBoxEvents.LearnMoreClick, (this.learnMoreClick$ = new Subject()))
    this.registerEvent(ConsentAltBoxEvents.RunClick, (this.runClick$ = new Subject()))
  }

  getTemplate(data: TemplateData): TemplateResult {
    this.currentType = data.consentType

    if (!data.loadingState) {
      return html` ${this.getHeaderContent(data)} ${this.getButtonsSection(data)} `
    } else {
      return html`
        <p class="cookie-alt__message">
          ${this.translate(this.isVideoType() ? AppLabels.AltVideoLoadingMessage : AppLabels.AltResourceLoadingMessage)}
        </p>
      `
    }
  }

  private emitLearnMoreClickEvent(): void {
    this.learnMoreClick$.next(new Date())
  }

  /**
   * @deprecated will be removed in version 3.0
   */
  private isVideoType(): boolean {
    return [ConsentBoxAltType.Youtube, ConsentBoxAltType.Vimeo].includes(this.currentType)
  }

  private emitRunClickEvent(): void {
    this.runClick$.next(this.currentType)
  }

  private getButtonsSection(data: TemplateData): TemplateResult {
    return html`
      <button
        class="c-btn c-btn--text-emerald cookie-alt__learn-more"
        @click="${this.emitLearnMoreClickEvent.bind(this)}"
      >
        ${this.getMoreButtonLabel(data)}
      </button>

      <button class="c-btn c-btn--icon-right cookie-alt__run-btn" @click="${this.emitRunClickEvent.bind(this)}">
        ${this.getAcceptButtonLabel(data)}
        <i
          class="${this.isVideoType() ? 'interface-icon interface-icon-interface_video' : 'ca-icon ca-icon-permission'}"
        ></i>
      </button>
    `
  }

  private getMoreButtonLabel(data: TemplateData): string {
    if (data.configuration?.moreBtnLabel) {
      return data.configuration.moreBtnLabel
    }

    return this.translate(AppLabels.AltLearnMoreBtnLabel)
  }

  private getAcceptButtonLabel(data: TemplateData): string {
    if (data.configuration?.acceptBtnLabel) {
      return data.configuration.acceptBtnLabel
    }

    if (this.isVideoType()) {
      return this.translate(AppLabels.AltLoadBtnLabel)
    }

    return this.translate(AppLabels.AltAgreeBtnLabel)
  }

  private getHeaderContent(data: TemplateData): TemplateResult {
    let headerMessage = ''

    if (data.configuration?.header) {
      headerMessage = data.configuration.header
    } else {
      headerMessage = this.getDefaultHeaderMessage()
    }

    return html` <span class="cookie-alt__header"> ${headerMessage} </span> `
  }

  private getDefaultHeaderMessage(): string {
    switch (this.currentType) {
      case ConsentBoxAltType.Youtube:
        return this.translate(AppLabels.AltYoutubeAgreement)
      case ConsentBoxAltType.Vimeo:
        return this.translate(AppLabels.AltVimeoAgreement)
      case ConsentBoxAltType.LinkedIn:
        return this.translate(AppLabels.AltLinkedInAgreement)
      case ConsentBoxAltType.GoogleMaps:
        return this.translate(AppLabels.AltGoogleMapsAgreement)
      case ConsentBoxAltType.Spotify:
        return this.translate(AppLabels.AltSpotifyAgreement)

      case ConsentBoxAltType.Marketing:
        return this.translate(AppLabels.AltMarketingAgreement)
      case ConsentBoxAltType.Statistics:
        return this.translate(AppLabels.AltStatisticsAgreement)
      case ConsentBoxAltType.Preferences:
        return this.translate(AppLabels.AltPreferencesAgreement)

      default:
        return ''
    }
  }
}
