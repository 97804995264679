export enum ConsentAltBoxEvents {
  LearnMoreClick = 'learnMoreClick',
  RunClick = 'runClick'
}

export enum ConsentBoxAltType {
  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  Youtube = 'youtube',

  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  Vimeo = 'vimeo',

  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  LinkedIn = 'linkedin',

  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  GoogleMaps = 'google-maps',

  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  Spotify = 'spotify',

  Marketing = 'marketing',
  Statistics = 'statistics',
  Preferences = 'preferences'
}

export interface ConsentAltConfiguration {
  header: string
  moreBtnLabel: string
  acceptBtnLabel: string
}
