import { type CookieConsentDetailsType } from './consent-data.model'

export enum SupportedLanguages {
  Pl = 'pl',
  En = 'en',
  De = 'de'
}

export enum AppLabels {
  AcceptAllBtnLabel = 'ACCEPT_ALL_BTN_LABEL',
  RejectAllBtnLabel = 'REJECT_ALL_BTN_LABEL',
  AltAgreeBtnLabel = 'ALT_AGREE_BTN_LABEL',
  AltResourceLoadingMessage = 'ALT_RESOURCE_LOADING_MESSAGE',
  AltLearnMoreBtnLabel = 'ALT_LEARN_MORE_BTN_LABEL',
  DetailHistoryTitle = 'DETAIL_HISTORY_TITLE',
  HistoryItemAccepted = 'HISTORY_ITEM_ACCEPTED',
  HistoryItemRefused = 'HISTORY_ITEM_REFUSED',
  ManageOptsBtnLabel = 'MANAGE_OPTS_BTN_LABEL',
  SaveConsentBtnLabel = 'SAVE_CONSENTS_BTN_LABEL',
  AltMarketingAgreement = 'ALT_MARKETING_AGREEMENT',
  AltStatisticsAgreement = 'ALT_STATISTICS_AGREEMENT',
  AltPreferencesAgreement = 'ALT_PREFERENCES_AGREEMENT',

  /**
   * @deprecated Will be remove in 3.0 version
   */
  AltVideoLoadingMessage = 'ALT_VIDEO_LOADING_MESSAGE',

  /**
   * @deprecated Will be remove in 3.0 version
   */
  AltLoadBtnLabel = 'ALT_LOAD_BTN_LABEL',

  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  AltGoogleMapsAgreement = 'ALT_GOOGLE_MAPS_AGREEMENT',

  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  AltLinkedInAgreement = 'ALT_LINKEDIN_AGREEMENT',

  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  AltVimeoAgreement = 'ALT_VIMEO_AGREEMENT',

  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  AltYoutubeAgreement = 'ALT_YOUTUBE_AGREEMENT',

  /**
   * @deprecated Use consent category keys instead of tool keys, tools keys will be removed in 3.0 version
   */
  AltSpotifyAgreement = 'ALT_SPOTIFY_AGREEMENT'
}

export type AppTranslationConfig = {
  [key in AppLabels | CookieConsentDetailsType]: {
    [key in SupportedLanguages]: string
  }
}
